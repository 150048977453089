import React from 'react'
import { Box, Link, Text, Center, VStack, Spacer } from '@chakra-ui/react'
import { motion } from 'framer-motion'



type CourseProps = {
    title: string;
    subTitle: string;
    image: any;
    href: string;
}


const Course = ({title, subTitle, image, href}: CourseProps) => {
    
  return (
        <Link
            href={href}>
        <motion.div
            whileHover={{ scale: 1.05 }}
        >
            <Box
                maxW={'800px'}
                minW={'250px'}
                minH={'400px'}
                backgroundImage={image}
                backgroundColor={'black'}
                backgroundSize={'cover'}
                backgroundPosition={'center'}
                backgroundRepeat={'no-repeat'}
                borderRadius={'20'}
                paddingX={'2vw'}
                position={'relative'}
                >
                    <Box
                        position={'absolute'}
                        top={0}
                        left={0}
                        w={'100%'}
                        h={'50%'}
                        bgGradient={'linear(to-b, rgba(0, 0, 0, 0.8), transparent)'}
                        borderRadius={'20'}
                        zIndex={1}
                    />
                    <Center
                        paddingTop={'2vh'}
                        >
                        <VStack>
                        <Text
                            color={'white'}
                            fontSize={'26px'}
                            fontWeight={'bold'}
                            verticalAlign={'top'}
                            textAlign={'center'}
                            zIndex={2}
                            >
                            {title}
                        </Text>
                        <Text
                            color={'white'}
                            fontSize={'18px'}
                            fontWeight={'light'}
                            verticalAlign={'top'}
                            textAlign={'center'}
                            zIndex={2}
                            >
                            {subTitle}
                        </Text>
                        </VStack>
                    </Center>
                    <Spacer 
                        height={'30vh'}
                    />

            </Box>
        
        </motion.div>
        </Link>
  )
}

export default Course
