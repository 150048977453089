import React from 'react'
import { Wrap, Box, SimpleGrid, Text } from '@chakra-ui/react'
import { INews } from "../data/types"
import News from "./News"


type NewsPropsList = {
  news?: Array<INews>
}


const NewsList = ({news}: NewsPropsList) => {

  if (!news) {
    return (
    <Text textAlign={'center'}>No news available</Text>
    )
  }
  
  return (

    <SimpleGrid
        spacing={'4vw'}
        templateColumns={{ sm: '1fr', lg: '1fr 1fr 1fr' }}
      >
        {
          news && news.map((course, idx) => {
            return (
              <News
                title={course.title}
                subTitle={course.subTitle}
                id={course.id}
                image={course.image}
              />
            )
          }
        )}
    </SimpleGrid>

  )
}

export default NewsList
