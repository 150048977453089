import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type State = {
    isContactFormOpen: boolean;
    contactFormCustomText?: string;
    news: Array<News>;
    loadingNews: boolean;
  };

type News = {
    title: string;
    subTitle?: string;
    content: string;
    pictures: Array<string>;

}

const initialState: State = {
    isContactFormOpen: false,
    contactFormCustomText: undefined,
    news: [],
    loadingNews: false,
  };

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleContactForm: (state) => {
            state.isContactFormOpen = !state.isContactFormOpen;
        },
        setContactFormCustomText: (state, action: PayloadAction<string>) => {
            state.contactFormCustomText = action.payload;
        }
    }   
 });

 export const { toggleContactForm, setContactFormCustomText } = appSlice.actions;

 export default appSlice.reducer;
