import { extendTheme } from "@chakra-ui/react";
import './styles.css'



const theme = extendTheme({
    fonts: {
        heading: "Rexton",
        body: "Cera Pro",
    },
    colors: {
        primary: "#FFD70a",
        secondary: "#FFA500",
        darkColor: "#000000",
        lightColor: "#FFFFFF",
    },
    fontSizes: {
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "18px",
        xl: "20px",
        "2xl": "24px",
        "3xl": "28px",
        "4xl": "36px",
        "5xl": "48px",
        "6xl": "64px",
      },
    
})

export default theme;