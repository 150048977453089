'use client'

import { ReactNode } from 'react'

import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  Link,
} from '@chakra-ui/react'

import { FaMap, FaPhone } from 'react-icons/fa'

import { NavLink } from 'react-router-dom'



import Logo from './Logo'

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  )
}

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW={'6xl'} py={10}
        width={{base:'90vw',md:'80vw'}}
      >
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '3fr 1fr 1fr 1fr 1fr' }}
          spacing={8}>
          <Stack spacing={4}>
            <Box>
              <Logo />
            </Box>
            <Link isExternal href="https://goo.gl/maps/TY7v3JFMLCvvHL6X7">
              <Text fontSize={'sm'}>ul. "Konche Vihrogonche" 401A, Druzba 2, Sofia.</Text>
            </Link>
            <Link  href="tel:+359885602809">
              <Text fontSize={'sm'}>+359 88 560 2809</Text>
            </Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Курсове</ListHeader>
            <Box as={Link} href={'/ballet'}>
              Класически балет
            </Box>
            <Box as={Link} href={'/modern'}>
              Модерен балет
            </Box>
            <Box as={Link} href={'/stretching'}>
            Стречинг за възрастни
            </Box>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>информация</ListHeader>
            <Box as={Link} href={'/about'}>
              За нас
            </Box>
            <Box as={Link} href={'/login'}>
              вход
            </Box>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Помощ</ListHeader>
            <Box as={Link} href={'#'}>
              Политика за поверителност
            </Box>
            <Link isExternal href={'https://renzoparagliola.com'}>
              Развитие
            </Link>
          </Stack>
         
          <Stack align={'flex-start'}>
            <ListHeader>Последвайте ни</ListHeader>
            <Box as={Link} href={'https://www.facebook.com/inchilza'}>
              Facebook
            </Box>
            <Box as={Link} href={'https://instagram.com/inchilza.dance.studio'}>
              Instagram
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  )
}
