import { VStack, Text } from '@chakra-ui/react'
import React from 'react'

type props = {
    title: string;
    subTitle: string;
    time?: string[];
    content: string[];
}

const TextContent = ({title,subTitle,time,content} : props) => {
  return (
    <VStack
        alignItems={'flex-start'}
        gap={5}
        >
        <Text
            fontSize={'2xl'}
            fontWeight={'normal'}
            fontFamily={'body'}>

            {subTitle}
        </Text>
        { content.map((paragraph, index) => { 
            return (
                <Text
                    fontSize={'xl'}
                    fontWeight={'light'}
                    fontFamily={'body'}
                    key={index}>
                    {paragraph}
                </Text>
            )}
        )}
    </VStack>
  )
}

export default TextContent
