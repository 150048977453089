import React from 'react'
import { Wrap, Box, SimpleGrid } from '@chakra-ui/react'
import Course from './Course'

type CourseProps = {
  title: string;
  subTitle: string;
  image: any;
  href: string;
}

type CourseListProps = {
  courses: Array<CourseProps>
}


const CourseList = (props: CourseListProps) => {
  return (
    <SimpleGrid
        spacing={'4vw'}
        columns={{base:1, md:2, lg:3}}
        
      >
        {
          props.courses.map((course) => {
            return (
              <Course
                title={course.title}
                subTitle={course.subTitle}
                href={course.href}
                image={course.image}
              />
            )
          }
        )}
    </SimpleGrid>

  )
}

export default CourseList
