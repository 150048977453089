import React from 'react'
import { Box, Text, VStack, SimpleGrid, Image } from '@chakra-ui/react'
import TextContentAbout from '../components/TextContentAbout'
import Carousel from '../components/Carousel'
import Footer from '../components/Footer'


const data = [
  {
    type: 'subTitle',
    content: 'Ива Делева',
  },
  {
    type: 'content',
    content: 'На 9 годишна възраст постъпва в Национално училище за танцово изкуство, със специалност  ,,Класически танц’’ като през 2017 година успешно завършва с отличие. Същата година е наградена със стипендия на името на ,,Анастас Петров’’ за високи постижения в танцовото изкуство.'
    +'Веднага след завършването си постъпва в Софийска опера и балет като два сезона е част от трупата на балета и активно взима участие в целия репертоар. Паралелно с това продължава балетното си образованието в Академия за музикално, танцово и изобразително изкуство ,,Проф. Асен Диамандиев“, със специалност ,,Балетна педагогика и хореография’’  като през 2022 година завършва бакалавърската си степен с отличие.'
    +'В периода между 2015 и 2023 година активно се занимава с преподавателска дейност в различни танцови школи и училища като част от тях са:',
  },
  {
    type: 'list',
    content: '',
    listElements: [
      'Народно читалище – град Перник',
      'Балетна школа ,,Маша Илиева’’',
      'Балетна школа ,,Инчилза’’',
      'Частно основно училище ,,Д-р. Петър Берон’’',
      'Балетна школа ,,Шара Карсакпаева’’'
    ]
  },
  {
    type: 'content',
    content: 'Благодарение на на професионалното си образование в едни от най-добрите училища на България, получава сериозна подготовка в сферата на танцовото изкуство.'
  }
]

const carouselPics = [
  'https://res.cloudinary.com/daduurukb/image/upload/v1693500477/inchilza/deleva.jpg',
]


const TeachersPage = () => {
    return (
        <>
            <Box
                bgGradient={'linear(to-tr, pink.100, orange.200)'}
                width={'100%'}
                position="relative"
                padding={'12vh 0vw'}
                 >
                    <VStack
                        width={{base:'90vw',md:'70vw'}}
                        margin={'auto'}
                      >
                        <Text
                            fontSize={'2xl'}
                            fontFamily={'heading'}
                            fontWeight={'bold'}
                            padding={'2vh 0'}
                            >учители</Text>
                            <SimpleGrid
                              columns={{base:1, md:1}}
                              bg={'rgba(255,255,255,0.5)'}
                              borderRadius={'10'}
                              w={'100%'}
                              gap={'4vw'}
                              minH={{base:'20vh', md:'50vh'}}
                              padding={'6vh 3vw'}>
                                <Carousel images={carouselPics}/>
                                  <VStack
                                    alignItems={'left'}>
                                  {
                                    data.map((item, index) => {
                                      return (
                                        <TextContentAbout
                                          type={item.type}
                                          content={item.content}
                                          align={'left'}
                                          key={index}
                                          listElements={item.listElements}
                                        />
                                      )
                                    })
                                  }
                                  </VStack>
                            </SimpleGrid>
    
                    </VStack>
    
            </Box>
            <Footer></Footer>
    
        </>
      )
}

export default TeachersPage
