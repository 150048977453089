import { 
    Text, 
    Image,
    ListItem,
    UnorderedList, 
} from '@chakra-ui/react'
import React from 'react'

import { FaStar } from 'react-icons/fa'

import Carousel from './Carousel'

type props = {
    type: string;
    content: string;
    index?: number;
    align?: 'center' | 'left' | 'right' | 'justify' | 'inherit' | 'initial' | 'revert' | 'unset' | undefined;
    listElements?: string[];
}

const TextContentAbout = ({type, content, index, listElements, align} : props) => {
    if (align === undefined) {
        align = 'center'
    }
  return (
    <>
        {
            type === 'subTitle' &&
            <Text
                fontSize={'4xl'}
                textAlign= {align}
                fontWeight={'normal'}
                fontFamily={'body'}
                key={index}
                >

                {content}
            </Text>
        }
        {
            type === 'image' &&
            <Image src={content} 
                borderRadius={'10'}
                alt={'picture'}
                width={{base:'90vw',md:'60vw'}}
                fit={'cover'}
                align={align}
                height={{base:'50vh',md:'60vh'}}
                margin={'auto'}
                >
            </Image>
        }
        {
            type === 'carousel' &&
            <Carousel 
                images={listElements || []}
            />
        }
        {
            type === 'content' &&
            <Text
                maxW={'900px'}
                textAlign={align}
                fontSize={'xl'}
                fontWeight={'light'}
                fontFamily={'body'}
                key={index}
                >
                {content}
            </Text>
        }
        {
            type === 'list' && 
            <>
            <Text
                fontSize={'4xl'}
                textAlign={align}
                fontWeight={'light'}
                fontFamily={'body'}
                key={index}
                paddingTop={'8vh'}
                >
                {content}
            </Text>
            <UnorderedList spacing={'2vh'}
                margin={'0 2vw'}
                maxW={'900px'}
                paddingBottom={'8vh'}>
                {
                    listElements?.map((item, index) => {
                        return (
                                <ListItem
                                    fontSize={'xl'}
                                    fontWeight={'light'}
                                    fontFamily={'body'}
                                    key={index}
                                    >
                                    {item}
                                </ListItem>
                        )
                    })
                }
            </UnorderedList>
            </>
        }
    </>
  )
}

export default TextContentAbout
