import * as React from "react"
import HomePage from "./routes/HomePage"
import BalletPage from "./routes/BalletPage"
import AboutPage from "./routes/AboutPage"
import CoursePage from "./routes/CoursePage"
import NewsPage from "./routes/NewsPage"
import ModernPage from "./routes/ModernPage"
import StretchingPage from "./routes/StretchingPage"
import ErrorPage from "./routes/ErrorPage"
import SchedulePage from "./routes/SchedulePage"
import LoginPage from "./routes/LoginPage"
import TeachersPage from "./routes/TeachersPage"
import SingleNewsPage from "./routes/SingleNewsPage"

import { Analytics } from '@vercel/analytics/react';

import { Provider } from "react-redux"
import { store } from "../src/store/store"

import {
  ChakraProvider, SlideFade,
} from "@chakra-ui/react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import theme from "./theme"
import Navbar from "./components/Navbar"
import RouteAnimation from "./components/RouteAnimation"

import ScrollToTop from "./components/ScrollToTop"



export const App = () => {
  
  return (
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Analytics />
        <RouteAnimation>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/school" element={<h1>School</h1>} />
            <Route path="/courses" element={<CoursePage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/news/:id" element={<SingleNewsPage />} />
            <Route path="/teachers" element={<TeachersPage />} />
            <Route path="/ballet" element={<BalletPage/>} />
            <Route path="/modern" element={<ModernPage/>} />
            <Route path="/stretching" element={<StretchingPage/>} />
            <Route path="/schedule" element={<SchedulePage/>} />
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/*" element={<ErrorPage />} />
          </Routes>
        </RouteAnimation>
      </BrowserRouter>
    </Provider>
  </ChakraProvider>
  )
}
