import React from 'react'
import { Text, HStack, SimpleGrid, VStack, Divider, Button } from '@chakra-ui/react'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import TextContent from '../components/TextContent'
import Footer from '../components/Footer'
import ClassDisplay from '../components/ClassDisplay'
import ButtonContactForm from '../components/ButtonContactForm'
import { getSchedule } from '../components/ScheduleTable'


const examplePics = [
  'https://res.cloudinary.com/daduurukb/image/upload/v1692810894/inchilza/c9dzqewwzppvqeuxuvln.jpg',
  'https://res.cloudinary.com/daduurukb/image/upload/v1692810895/inchilza/cul9bvhfjuj75h62ptoo.jpg',
  'https://res.cloudinary.com/daduurukb/image/upload/v1692810895/inchilza/bdqqqbag2z8bdcspgcir.jpg',
  'https://res.cloudinary.com/daduurukb/image/upload/v1692810894/inchilza/vrxpnlzz2iqzob0peude.jpg'
]

const classes = getSchedule('Модерен балет')

const ModernPage = () => {
  return (
    <>
        <Carousel images={
            examplePics
        }/>
        <Text
            marginLeft={'auto'}
            marginRight={'auto'}
            width={{base:'80vw',md:'60vw'}}
            fontSize={'4xl'}
            marginTop={{base:'2vh', md:'5vh'}}
            fontFamily={'heading'}
            fontWeight={'bold'}
            verticalAlign={'top'}
            align={'left'}
            >
            {'Модерен балет'}
        </Text>
        <SimpleGrid
          marginLeft={'auto'}
          marginRight={'auto'}
          width={{base:'80vw',md:'60vw'}}
          marginTop={{base:'2vh', md:'5vh'}}
          marginBottom={{base:'2vh', md:'5vh'}}
          columns={{base:1, lg:2}}
          columnGap={10}>
          <TextContent
              title={''}
              subTitle={''}
              content={['Модерният балет е форма на танц, която съчетава в себе си елементи от  класическия и модерния танц. В този танцов стил артистите разрушават строгата рамка на класическия балет и дават свобода на тялото си, чрез което изразяват широк спектър от движения. По време на обучението си децата ще подобрят пластичността на тялото си, ще развият усета си за ритмичност, ще се научат да импровизират в реално време, което ще даде свобода на тяхната артистичност и креативност.',
              'Модерният балет е подходящ за всички деца като най-малките ни възпитаници са на четири годишна възраст. Очакваме ги с нетърпение!']}
          />
          <VStack
            borderLeft={'4px solid'}
            borderColor={'white'}
            paddingTop={'0'}
            marginTop={{base:'5vh',md:'0'}}
            >
              <Text
                fontSize={'2xl'}
                fontWeight={'light'}
                >
                Налични класове:
              </Text>
              <SimpleGrid
                columns={{base:2, lg:2}}
                alignContent={'left'}
                gap={'1vw'}
                >
                  {classes.map((classItem, index) => {
                    return (
                      <ClassDisplay
                        title={classItem.title}
                        time={classItem.time}
                        key={index}
                      />
                    )
                  })}
              </SimpleGrid>

              <ButtonContactForm />

          </VStack>

          
        </SimpleGrid>

        <Footer />

    </>
  )
}

export default ModernPage
