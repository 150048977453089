import { Text, Box, VStack, SimpleGrid } from '@chakra-ui/react'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import TextContent from '../components/TextContent'
import Footer from '../components/Footer'
import CourseList from '../components/CourseList'


import ballet2 from '../assets/course2-4.jpg'
import ballet5 from '../assets/course5-7.jpg'
import ballet8 from '../assets/course8.jpg'
import moderny from '../assets/moderny.jpg'
import moderno from '../assets/modernold.jpg'
import stretching from '../assets/stretching.jpg'

const courses = [
  {
    title: 'Класически балет за деца',
    subTitle: '3-4 години',
    image: ballet2,
    href: '/ballet'
  },
  {
    title: 'Класически балет за деца',
    subTitle: '5-7 години',
    image: ballet5,
    href: '/ballet'
  },
  {
    title: 'Класически балет за деца',
    subTitle: '8+ години',
    image: ballet8,
    href: '/ballet'
  },
  {
    title: 'Модерен балет за деца',
    subTitle: 'начинаещи',
    image: moderny,
    href: '/modern'
  },
  {
    title: 'Модерен балет за деца',
    subTitle: 'напреднали',
    image: moderno,
    href: '/modern'
  },
  {
    title: 'Стречинг и мобилност на тялото',
    subTitle: 'възрастни',
    image: stretching,
    href: '/stretching'
  },
]



const CoursePage = () => {
  return (
    <>
        <Box
            bgGradient={'linear(to-tr, pink.100, orange.200)'}
            width={'100%'}
            position="relative"
            padding={'12vh 0vw'}
             >
                <VStack
                    width={{base:'90vw',md:'80vw'}}
                    margin={'auto'}
                  >
                    <Text
                        fontSize={'2xl'}
                        fontFamily={'heading'}
                        fontWeight={'bold'}
                        padding={'2vh 0'}
                        >курсове</Text>
                        <Box
                          bg={'rgba(255,255,255,0.5)'}
                          borderRadius={'10'}
                          w={'100%'}
                          padding={'6vh 2vw'}>
                            <CourseList courses={courses}/>
                        </Box>

                </VStack>

        </Box>
        <Footer></Footer>

    </>
  )
}

export default CoursePage
