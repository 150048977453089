import { Text, HStack, SimpleGrid, VStack, Button } from '@chakra-ui/react'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import TextContent from '../components/TextContent'
import Footer from '../components/Footer'
import ClassDisplay from '../components/ClassDisplay'
import ButtonContactForm from '../components/ButtonContactForm'


const examplePics = [
    'https://res.cloudinary.com/daduurukb/image/upload/v1692810896/inchilza/u6mymdrixt73x1hdjjqb.jpg',
    'https://res.cloudinary.com/daduurukb/image/upload/v1692810896/inchilza/p36mw2uvfedvjscwj7l1.jpg',
    'https://res.cloudinary.com/daduurukb/image/upload/v1692810895/inchilza/lzzrp1nc5powabpggbpn.jpg'
]


const classes = [
  {
    title: 'възрастни',
    time: [
      {
        day: 'Вт.',
        time: '19:30 - 20:30'
      },
      {
        day: 'Чет.',
        time: '19:30 - 20:30'
      },
    ]
  },
]

const StretchingPage = () => {
  return (
    <>
        <Carousel images={
            examplePics
        }/>
        <Text
            marginLeft={'auto'}
            marginRight={'auto'}
            width={{base:'80vw',md:'60vw'}}
            fontSize={'4xl'}
            marginTop={{base:'2vh', md:'5vh'}}
            fontFamily={'heading'}
            fontWeight={'bold'}
            verticalAlign={'top'}
            align={'left'}
            >
            {'Стречинг за възрастни'}
        </Text>
        <SimpleGrid
          marginLeft={'auto'}
          marginRight={'auto'}
          width={{base:'80vw',md:'60vw'}}
          marginTop={{base:'2vh', md:'5vh'}}
          marginBottom={{base:'2vh', md:'5vh'}}
          columns={{base:1, lg:2}}
          columnGap={10}>
          <TextContent
              title={''}
              subTitle={''}
              content={['Стречингът е физическа тренировка, която има за цел да развие гъвкавостта и мобилността на тялото. Тези класове ще спомогнат за подобряване на тонуса на вашето тяло, също така ще окажат положителен ефект върху всички мускулни групи.',
              'При редовно практикуване ще забележите изваяност на тялото  и по-добра осанка, ще усетите спад на умората и стреса, който се натрупва в забързаното ни ежедневие.',
              'Класовете ни са подходящи за хора на всякаква възраст-както напреднали, така и  начинаещи. Очакваме Ви с нетърпение!'
            ]}
          />
          <VStack
            borderLeft={'4px solid'}
            borderColor={'white'}
            paddingTop={'0'}
            marginTop={{base:'5vh',md:'0'}}
            >
              <Text
                fontSize={'2xl'}
                fontWeight={'light'}
                >
                Налични класове:
              </Text>
              <SimpleGrid
                columns={{base:1, lg:1}}
                alignContent={'left'}
                gap={'1vw'}
                >
                  {classes.map((classItem, index) => {
                    return (
                      <ClassDisplay
                        title={classItem.title}
                        time={classItem.time}
                        key={index}
                      />
                    )
                  })}
              </SimpleGrid>
              <ButtonContactForm />

          </VStack>

          
        </SimpleGrid>

        <Footer />

    </>
  )
}

export default StretchingPage
