import React, { useState, useEffect } from 'react'
import { Box, Image, Fade } from '@chakra-ui/react'
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons'

import Fallback from './Fallback'

type Props = {
  images: string[]
}

const Carousel = ({ images }: Props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [manual, setManual] = useState(false)
  const [dragStartX, setDragStartX] = useState(0)
  const [dragEndX, setDragEndX] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
        if (manual === false){
            setCurrentImageIndex((currentImageIndex + 1) % images.length)
        }
    }, 3000)
    return () => clearInterval(intervalId)
  }, [currentImageIndex, images.length])

  const handleArrowClick = (direction: 'left' | 'right') => {
    setManual(true)
    if (direction === 'left') {
      setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length)
    } else {
      setCurrentImageIndex((currentImageIndex + 1) % images.length)
    }
  }

  const handleDragStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setDragStartX(event.touches[0].clientX)
  }

  const handleDragEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    setDragEndX(event.changedTouches[0].clientX)
    if (dragEndX - dragStartX > 50) {
      handleArrowClick('left')
    } else if (dragEndX - dragStartX < -50) {
      handleArrowClick('right')
    }
  }

  const handleDragMove = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault()
  }
  

  return (
    <Box 
        w="100%" h="65vh" 
        position="relative"
        onTouchStart={handleDragStart}
        onTouchEnd={handleDragEnd}
        onTouchMove={handleDragMove}
        >
      {images.map((imageUrl, index) => (
        <Fade key={imageUrl} in={index === currentImageIndex}>
          <Image
            src={imageUrl}
            alt={`Carousel image ${index}`}
            w="100vw"
            h="65vh"
            position="absolute"
            objectFit={'cover'}
            objectPosition="top"
            top="0"
            left="0"
            fallback={<Fallback />}
          />
        </Fade>
      ))}
      <Box
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        left={{base:"5vw", 'md':"10vw"}}
        zIndex="1"
        cursor="pointer"
        /*bg={'rgba(255,255,255,0.5)'}
        borderRadius={'50%'}
        backdropFilter={'blur(10px)'}*/
        onClick={() => handleArrowClick('left')}
      >
        <ArrowBackIcon
            margin={'0.5vw'}
            boxSize={8} />
      </Box>
      <Box
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        right={{base:"5vw", 'md':"10vw"}}
        zIndex="1"
        cursor="pointer"
        onClick={() => handleArrowClick('right')}
      >
        <ArrowForwardIcon 
            boxSize={8}/>
      </Box>
    </Box>
  )
}

export default Carousel