import React from 'react'
import { SlideFade } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

import {motion} from 'framer-motion'

const RouteAnimation = (props: PropsWithChildren<unknown>) => {
  return (
    <SlideFade in={true} offsetY={'-20px'}>
        {props.children}
    </SlideFade>
  )
}

export default RouteAnimation
