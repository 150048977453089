import { Text, HStack, SimpleGrid, VStack, Divider, Button } from '@chakra-ui/react'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import TextContent from '../components/TextContent'
import Footer from '../components/Footer'
import { SlideFade } from '@chakra-ui/react'

import ClassDisplay from '../components/ClassDisplay'
import ButtonContactForm from '../components/ButtonContactForm'
import { getSchedule } from '../components/ScheduleTable'


const examplePics = [
    'https://res.cloudinary.com/daduurukb/image/upload/v1692810891/inchilza/jdu5lbqltsgvk7tklmmq.jpg',
    'https://res.cloudinary.com/daduurukb/image/upload/v1692810892/inchilza/xdsgj1qoyqznhhbh8teh.jpg',
    'https://res.cloudinary.com/daduurukb/image/upload/v1692810891/inchilza/pnaoldohbigobnma12oy.jpg',
    
]



const classes = getSchedule('Класически балет')

const BalletPage = () => {
  return (
    <>
        <Carousel images={
            examplePics
        }/>
        <Text
            marginLeft={'auto'}
            marginRight={'auto'}
            width={{base:'80vw',md:'60vw'}}
            fontSize={'4xl'}
            marginTop={{base:'2vh', md:'5vh'}}
            fontFamily={'heading'}
            fontWeight={'bold'}
            verticalAlign={'top'}
            align={'left'}
            >
            {'Класически балет'}
        </Text>
        <SimpleGrid
          marginLeft={'auto'}
          marginRight={'auto'}
          width={{base:'80vw',md:'60vw'}}
          marginTop={{base:'2vh', md:'5vh'}}
          marginBottom={{base:'2vh', md:'5vh'}}
          columns={{base:1, lg:2}}
          columnGap={10}>
          <TextContent
              title={''}
              subTitle={''}
              content={['Класическият балет е сценично изкуство, чието основно изразно средство е танцът. То е създадено на основата на музиката, драматургията, пантомима, сценографията и хореографията. Разглеждан като танцов стил, балетът изгражда физическа издръжливост, развива гъвкавост, подобрява координацията и баланса на тялото. Практикуван от ранна детска възраст, балетът оказва благоприятно влияние върху стойката на тялото и походката. Балетното изкуство възпитава високо ниво на дисциплина, а постигнатите успехи в залата и на сцена амбицират децата и влияят положително на тяхното самочувствие и емоционално състояние.',
              'Уроците протичат в няколко етапа, съобразени с нивото и възрастта на учениците. Обучението се базира на утвърдена руска програма. Най-ранната възраст, в която приемаме деца е две години и шест месеца. Очакваме ги с нетърпение!']}
          />
          <VStack
            borderLeft={'4px solid'}
            borderColor={'white'}
            paddingTop={'0'}
            marginTop={{base:'5vh',md:'0'}}
            >
              <Text
                fontSize={'2xl'}
                fontWeight={'light'}
                >
                Налични класове:
              </Text>
              <SimpleGrid
                columns={{base:2, lg:2}}
                alignContent={'left'}
                gap={'1vw'}
                >
                  {classes.map((classItem, index) => {
                    return (
                      <ClassDisplay
                        title={classItem.title}
                        time={classItem.time}
                        key={index}
                      />
                    )
                  })}
              </SimpleGrid>

              <ButtonContactForm />

          </VStack>

          
        </SimpleGrid>

        <Footer />

    </>
  )
}

export default BalletPage
