import { Text, Box, VStack, Center, SimpleGrid } from '@chakra-ui/react'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import TextContent from '../components/TextContent'
import TextContentAbout from '../components/TextContentAbout'
import Footer from '../components/Footer'


const examplePics = [
  'https://res.cloudinary.com/daduurukb/image/upload/v1693499047/inchilza/tofix-EDIT.jpg',
  'https://res.cloudinary.com/daduurukb/image/upload/v1693498684/inchilza/pate23.jpg',
  'https://res.cloudinary.com/daduurukb/image/upload/v1693499525/inchilza/stretching.jpg',
  
]

const textContent = [
    {
      type: 'image',
      content: 'https://res.cloudinary.com/daduurukb/image/upload/v1693498395/inchilza/smallpate.jpg'
    },
    {
      type: 'subTitle',
      content: 'Мястото, което сбъдва мечти…',
    },
    {
      type: 'content',
      content: 'Основна цел на педагозите от танцово студио “Инчилза” е да развият пълния потенциал на учениците си. Работата ни е базирана на утвърдена руска програма, която се стреми към правилна постановка на тялото, детайлно изучаване на балетния екзерсис и усъвършенстване на танцовата техника. Нашите ученици се обучават в спокойна работна среда, изразяват себе си и се забавляват.',
    },
    {
      type: 'list',
      content: 'Защо да поверите децата си на нас?',
      listElements: [
          'Възпитаниците ни получават качествено начално обучение, което дава основа за бъдещо професионално образование в сферата на балетното изкуство. ',
          'Проявяваме индивидуален подход към всяко дете.',
          'Ние ще посеем семенцето на любовта към балета в сърцата на Вашите деца, както нашите учители са го направили. То ще остане там завинаги и ще озарява живота им.'
      ]
    },
    {
      type: 'carousel',
      content: '',
      listElements: examplePics
    },
    {
      type: 'subTitle',
      content: 'Малко повече за историята на студиото',
    },
    {
      type: 'content',
      content: 'Имало едно време едно малко момиченце с две опашки и голяма мечта. То много обичало да танцува и не можело да си представи, че някога ще спре. Малкото момиченце пораснало и тази любов никога не загаснала, а напротив - продължавала да гори все по-силно. И така 20 години по-късно от една голяма мечта и много любов се родило танцово студио ,,Инчилза’’.'
    },
    {
      type: 'content',
      content: 'Танцово студио ,,Инчилза’’ отваря врати през февруари 2023 година с първите си групи по класически танц. По това време класовете се провеждат в зала на друга танцова формация. От септември същата година се отваря първата самостоятелна танцова зала на студиото. На ред с това откриват и нови класове като: модерен балет и стречинг.'
    },
    {
      type: 'content',
      content: 'Ако искате да сте част от нашето малко голямо танцово семейство, където ще учим, ще обичаме и ще творим, не се замисляйте и се присъединете.'
    },
    {
      type: 'content',
      content: 'Очакваме Ви с нетърпение!'
    }

]

const AboutPage = () => {
  return (
    <>
        <Box
            bgGradient={'linear(to-tr, pink.100, orange.200)'}
            width={'100%'}
            position="relative"
            padding={'12vh 0vw'}
             >
                <VStack
                    width={{base:'90vw',md:'70vw'}}
                    margin={'auto'}
                  >
                    <Text
                        fontSize={'2xl'}
                        fontFamily={'heading'}
                        fontWeight={'bold'}
                        padding={'2vh 0'}
                        >За нас</Text>
                        <SimpleGrid
                          columns={{base:1, md:1}}
                          bg={'rgba(255,255,255,0.5)'}
                          borderRadius={'10'}
                          w={'100%'}
                          minH={{base:'20vh', md:'50vh'}}
                          padding={'6vh 3vw'}>
                            <VStack gap={'2vh'}>
                            {
                              textContent.map((item, index) => {
                                return (
                                  <TextContentAbout
                                    type={item.type}
                                    content={item.content}
                                    key={index}
                                    listElements={item.listElements}
                                  />
                                )
                              })
                            }
                            </VStack>
                        </SimpleGrid>

                </VStack>

        </Box>
        <Footer></Footer>

    </>
  )
}

export default AboutPage
