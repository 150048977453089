import {
    Box,
    SimpleGrid,
    VStack,
    Text,
  } from '@chakra-ui/react'


  interface IndividualLessonTime {
    day: string;
    time: string;
  }
  interface IndividualLesson {
    title: string;
    time: IndividualLessonTime[];
  }

  const formattedDay = (day: string) => {
    let res = day.substring(0,3)+"."
    if (day === 'Вторник') 
      res =  'Вт.';

    if (day === 'Четвъртък')
      res = 'Четв.';

    if (day === 'Петък')
      res = 'Пт.';

      return res
  }

  export const getSchedule = (lessonName: string) => {
    let res : IndividualLesson[] = [] 
    schedule.map((day) => {
      day.classes.map((lesson) => {
        if (lesson.title === lessonName) {
          let inserted = false;
          const formDay = formattedDay(day.day)
          
          res.map((item) => {
            if (item.title === lesson.subTitle) {

              item.time.push({
                day: formDay,
                time: lesson.time
              })
              inserted = true
            }
          })
          if (inserted === false){
            res.push({
              title: lesson.subTitle,
              time: [{
                day: formDay,
                time: lesson.time
              }]
            })
          }
        }
      })
    })
    return res
  }


  interface ScheduleType {
    title: string;
    subTitle: string;
    time: string;
  }
  interface Schedule {
    day: string;
    classes: ScheduleType[];
  }


  const schedule : Schedule[] = [
    {
    day: 'Понеделник',
    classes: [
      {
        title: 'Класически балет',
        subTitle: '3/4 годишни',
        time: '17:30 - 18:20',
      },
      {
      title: 'Модерни танци',
      subTitle: '5+ годишни',
      time: '18:30 - 19:30',
      },
      {
        title: 'Стречинг',
        subTitle: 'възрастни',
        time: '19:30 - 20:30',
      },
    ],
    },
    {
    day: 'Вторник',
    classes: [
    {
    title: 'Класически балет',
    subTitle: '5/6/7 годишни',
    time: '17:55 - 18:55',
    },
    {
      title: 'Класически балет',
      subTitle: '8+ годишни',
      time: '18:55 - 19:55',
    },
    ],
    },{
    day: 'Сряда',
    classes: [
    {
    title: 'Български танци',
    subTitle: '4/10 годишни',
    time: '18:55 - 19:55',
    },
    {
    title: 'Български танци',
    subTitle: '11/18 годишни',
    time: '20:00 - 21:00',
    },
    ],
    },
    {
    day: 'Четвъртък',
    classes: [
    {
    title: 'Класически балет',
    subTitle: '3/4 годишни',
    time: '17:30 - 18:20',
    },
    {
    title: 'Модерни танци',
    subTitle: '5+ годишни',
    time: '18:30 - 19:30',
    
    },
    {
    title: 'Български танци',
    subTitle: '4/10 годишни',
    time: '19:30 - 20:30',
    },
    {
    title: 'Български танци',
    subTitle: '11/18 годишни',
    time: '20:30 - 21:30',
    },
    ]
    },
    {
    day: 'Петък',
    classes: [
      {
        title: 'Класически балет',
        subTitle: '5/6/7 годишни',
        time: '17:55 - 18:55',
      },
      {
        title: 'Класически балет',
        subTitle: '8+ годишни',
        time: '18:55 - 19:55',
      },
    ],
    },
    ]



const TableTitle = ({title}: {title:string}) => {
  return (
    <Box
      textAlign={'center'}
      fontWeight={'bold'}
      fontFamily={'heading'}
      fontSize={'lg'}

      p={2}
      >
      {title}
    </Box>
  )
}

const TableElements = ({title, subTitle, time}: {title:string, subTitle:string, time:string}) => {
  return (
    <Box
      bg={'white'}
      textAlign={'center'}
      fontWeight={'semibold'}
      fontSize={'lg'}
      borderRadius={'10'}
      w={'100%'}
      
      border={'1px solid black'}

      p={2}
      >
      <Text
        fontWeight={'bold'}
        fontSize={'md'}>{title}</Text>
      <Text
        fontSize={'sm'}>{'('+subTitle+')'}</Text>
      <Text
        fontSize={'sm'}
        fontWeight={'light'}>{time}</Text>
    </Box>
  )
}

const ScheduleTable = () => {
  return (
    <SimpleGrid
          bg={'rgba(255,255,255,0.5)'}
          gap={3}
          borderRadius={'10'}
          w={'100%'}
          padding={'4vh 2vw'}
          columns={{base:1,lg:6}}>
      {
          
          schedule.map((day) => (
            <VStack>
            <TableTitle title={day.day} />
              {
                day.classes && day.classes.map((lesson) => (
                  <TableElements title={lesson.title} subTitle={lesson.subTitle} time={lesson.time} />
                ))
              }
            </VStack>

          ))
      }
    
     </SimpleGrid>

  )
}

export default ScheduleTable
