import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Center,
    Image,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
  } from '@chakra-ui/react'
  import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
  } from '@chakra-ui/icons'

  import { NavLink, useParams, useLocation } from 'react-router-dom'
  import { motion } from 'framer-motion'

  import Logo from './Logo'
  import ContactForm from './ContactForm'

  import { useSelector, useDispatch } from 'react-redux'
  import { RootState } from '../store/store'
  import { toggleContactForm, setContactFormCustomText } from '../store/appSlice'


  import { ChatIcon, CalendarIcon } from '@chakra-ui/icons'
import { useEffect, useState } from 'react'
import { on } from 'events'
  
  export default function Navbar() {
    const { isOpen, onToggle } = useDisclosure()

    const [isMenuOpen, setMenuOpen] = useState(false)
    const closeMenu = () => setMenuOpen(false)
    const openMenu = () => setMenuOpen(true)
    const toggleMenu = () => setMenuOpen(!isMenuOpen)

    const isContactOpen = useSelector((state: RootState) => state.app.isContactFormOpen)
    const contactFormCustomText = useSelector((state: RootState) => state.app.contactFormCustomText)
    const dispatch = useDispatch()
    //const { isOpen: isContactOpen, onToggle: onContactToggle } = useDisclosure()
    const onContactToggle = () => {
      dispatch(setContactFormCustomText(''))
      dispatch(toggleContactForm())
    }

    const location = useLocation()

    useEffect(() => {
      closeMenu()
    }, [location])
  
    return (
      <Box>
        <ContactForm isOpen={isContactOpen} toggleOpen={onContactToggle} customText={contactFormCustomText}/>

        <Flex
          bg={'rgba(255,255,255,0.5)'}
          backdropFilter={'blur(10px)'}
          zIndex={99}
          position={'fixed'}
          marginLeft={{base:'5vw', md:'10vw'}}
          marginTop={'2vh'}
          width={{base:'90vw', md:'80vw'}}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderRadius={'10'}
          align={'center'}>

          <Logo hideMobile/>
          
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}>
            <IconButton
              onClick={toggleMenu}
              icon={isMenuOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'center' }} >
              <Logo hideDesktop/>
              
  
            <Center display={{ base: 'none', md: 'center' }} ml={10} >
              <DesktopNav />
            </Center>
          </Flex>
  
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}>
            {/*<Button as={'a'} fontSize={'sm'} fontWeight={400} onMouseDown={onScheduleToggle}>
              Schedule
    </Button>*/}
            <Button
              as={'a'}
              display={{ base: 'none', lg: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              w={{base:'10vw', md:'7vw'}}
              color={'white'}
              bg={'red.400'}
              onMouseDown={onContactToggle}
              _hover={{
                bg: 'red.300',
              }}>
               { isContactOpen ? <CloseIcon /> : "Свържете се"}
            </Button>
            <Button
              as={'a'}
              display={{ base: 'inline-flex', lg: 'none' }}
              fontSize={'sm'}
              fontWeight={600}
              color={'black'}
              bg={'transparent'}
              
              onMouseDown={onContactToggle}
              _hover={{
                bg: 'white',
              }}>
                { isContactOpen ? <CloseIcon /> : <ChatIcon />}
            </Button>
          </Stack>
        </Flex>
        
        {isMenuOpen && 
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
          >
            <MobileNav /> 
          </motion.div>
        }
      </Box>
    )
  }
  
  const DesktopNav = () => {
    const useLocationHook = useLocation()
  
    return (
      <Stack direction={'row'} spacing={6} >
        { useLocationHook.pathname != '/' && 
            <Box key={'Начало'}>
              <NavLink to={'/'}>
                  <Box
                    p={2}
                    fontSize={'md'}
                    bg={'transparent'}
                    borderRadius={'5'}
                    fontWeight={'bold'}
                    color={'black'}
                    _hover={{
                      textDecoration: 'underline',
                      color: 'gray.800',
                    }}>
                    {'Начало'}
                  </Box>
              </NavLink>
            </Box>
          }

        {NAV_ITEMS.map((navItem) => (
          
          <Box key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
              <NavLink to={navItem.href ?? '#'}>
                <Box
                  p={2}
                  fontSize={'md'}
                  bg={useLocationHook.pathname === navItem.href ? 'rgba(255,255,255,0.5)' : 'transparent'}
                  borderRadius={'5'}
                  fontWeight={'bold'}
                  color={'black'}
                  _hover={{
                    textDecoration: 'underline',
                    color: 'gray.800',
                  }}>
                  {navItem.label}
                </Box>
              </NavLink>
              </PopoverTrigger>
  
              {navItem.children && (
                <PopoverContent
                  border={0}
                  bg={'rgba(255,255,255,0.8)'}
                  zIndex={10}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}
      </Stack>
    )
  }
  
  const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
      <NavLink to={href ?? '#'}>
      <Box
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: 'pink.400' }}
              fontWeight={500}>
              {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Box>
      </NavLink>
    )
  }
  
  const MobileNav = () => {
    const useLocationHook = useLocation()
    return (
      <Stack
        position={'fixed'}
        top={'11vh'}
        left={'5vw'}
        borderRadius={'10'}
        width={'90vw'}
        zIndex={20}
        bg={'rgba(255,255,255,0.5)'} 
        backdropFilter={'blur(10px)'}
        p={4} 
        display={{ md: 'none' }}>
        {
          useLocationHook.pathname != '/' &&
          <Stack spacing={4}>
          <Box
            paddingTop={'1'}
            paddingBottom={'2'}
            as="a"
            href={'/'}
            justifyContent="space-between"
            alignItems="center"
            _hover={{
              textDecoration: 'none',
            }}>
            <Text fontWeight={'bold'} color={'black'}>
              {'Начало'}
            </Text>
          </Box>
          </Stack>
        }
         
        {MOBILE_NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    )
  }
  
  const MobileNavItem = ({ label, children, href }: NavItem) => {

  
    return (
      <Stack spacing={4} >
        <NavLink to={href ?? '#'}>
          <Box
            py={2}
            justifyContent="space-between"
            alignItems="center"
            _hover={{
              textDecoration: 'none',
            }}>
            <Text fontWeight={'bold'} color={'black'}>
              {label}
            </Text>
          </Box>
        </NavLink>
  
      </Stack>
    )
  }
  
  interface NavItem {
    label: string
    subLabel?: string
    children?: Array<NavItem>
    href?: string
  }


  
  const NAV_ITEMS: Array<NavItem> = [
    {
      label: 'За нас',
      href: '/about',
      children: [
        /*{
          label: 'School',
          href: '/school'
        },*/
        {
          label: 'за студиото',
          href: '/about'
        },
        {
          label: 'Уители',
          href: '/teachers'
        },
        
      ]
    },
    {
      label: 'Курсове',
      href:'/courses',
      children: [
        {
          label: 'Класически балет',
          subLabel: '2-4 години, 5-7 години, 8+ години',
          href: '/ballet',
        },
        {
          label: 'Модерен балет',
          subLabel: 'начинаещи, напреднали',
          href: '/modern',
        },
        {
          label: 'Стречинг и мобилност на тялото',
          subLabel: 'възрастни',
          href: '/stretching',
        }
      ],
    },
    {
      label: 'Актуално',
      href: '/news',
    },
    {
      label: 'График',
      href: '/schedule',
    },
  ]


  const MOBILE_NAV_ITEMS: Array<NavItem> = [
    {
      label: 'За нас',
      href: '/about',
    },
    {
      label: 'Учители',
      href: '/teachers'
    },
    {
      label: 'Курсове',
      href:'/courses',
      children: [
        {
          label: 'Класически балет',
          subLabel: '2-4 години, 5-7 години, 8+ години',
          href: '/ballet',
        },
        {
          label: 'Модерен балет',
          subLabel: 'начинаещи, напреднали',
          href: '/modern',
        },
        {
          label: 'Стречинг и мобилност на тялото',
          subLabel: 'възрастни',
          href: '/stretching',
        }
      ],
    },
    {
      label: 'Актуално',
      href: '/news',
    },
    {
      label: 'График',
      href: '/schedule',
    },
  ]