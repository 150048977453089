import { Box, Heading } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import React from 'react'

const Quote = (props: PropsWithChildren<unknown>) => {
  return (
    <Box
        bg={'gray.900'}
        padding={'10'}
        width={'100%'}
        borderRadius={'10'}>
        <Heading
            color={'white'}
            size={'lg'}
            textAlign={'center'}>
            {props.children}
        </Heading>
    </Box>
  )
}

export default Quote
