import { SimpleGrid, Text, VStack } from "@chakra-ui/react"

type ScheduleType = {
    day: string;
    time: string;
  }
  
  type classDisplayType = {
    title: string;
    time: ScheduleType[];
  }
  
  const Schedule = ({day, time}: ScheduleType) => {
    return (
      <SimpleGrid
        columns={2}
        gap={'1vw'}
        >
          <Text>{day}</Text>
          <Text>{time}</Text>
      </SimpleGrid>
    )
  }
  
  
  const ClassDisplay = ({title, time}:classDisplayType) => {
    return (
  
        <VStack
          border={'4px solid black'}
          padding={'2vh 2vw'}
          _hover={
            {
              bg:'pink.100',
            }
          }
          >
          <Text
            fontSize={'xl'}
            textAlign={'center'}
          >{title}</Text>
          <SimpleGrid
            columns={1}
            gap={'0.5vw'}
            >
              {time.map((schedule, index) => {
                return (
                  <Schedule
                    day={schedule.day}
                    time={schedule.time}
                    key={index}
                  />
                )
              })}
          </SimpleGrid>
        </VStack>
    )
  }

  export default ClassDisplay;