import React from 'react'
import { NavLink } from 'react-router-dom'
import { Image } from '@chakra-ui/react'
import LogoBlack from '../assets/logo_black.png'

type props = {
    hideMobile?: boolean
    hideDesktop?: boolean
}

const Logo = ({hideMobile, hideDesktop}:props) => {
    if (hideMobile) {
        return (
            <NavLink to="/">
                <Image
                    src={LogoBlack}
                    ml={'1'}
                    display={{base: 'none', md: 'block'}}
                    width={{base: '50px', md: '100px'}}
                    alt={'Inchilza Logo'}
                ></Image>
            </NavLink>
        )
    }
    else if (hideDesktop) {
        return (
            <NavLink to="/">
                <Image
                    src={LogoBlack}
                    ml={'1'}
                    display={{base: 'block', md: 'none'}}
                    width={{base: '100px', md: '100px'}}
                    alt={'Inchilza Logo'}
                ></Image>
            </NavLink>
        )
    }
    else {
        return (
            <NavLink to="/">
                <Image
                    src={LogoBlack}
                    ml={'1'}
                    width={{base: '100px', md: '100px'}}
                    alt={'Inchilza Logo'}
                ></Image>
            </NavLink>
        )
    }
}

export default Logo
