import React from 'react'
import { Box, Link, Text, Center, VStack, Spacer, Heading } from '@chakra-ui/react'
import { motion } from 'framer-motion'



type CourseProps = {
    title: string;
    subTitle: string;
    image: any;
    id: string;
    isEvidence?: boolean;
}


const News = ({title, subTitle, image, id, isEvidence}: CourseProps) => {
    
  return (
        <Link
            href={`news/${id}`}>
        <motion.div
            whileHover={{ scale: 1.05 }}
        >
            <Box
                {...(isEvidence && {maxW:'800px'})}
                minW={'250px'}
                minH={'400px'}
                backgroundImage={image}
                backgroundSize={'cover'}
                backgroundPosition={'center'}
                backgroundRepeat={'no-repeat'}
                borderRadius={'20'}
                position={'relative'}
                >
                    <Box
                        position={'absolute'}
                        top={'50%'}
                        left={0}
                        w={'100%'}
                        h={'50%'}
                        bgGradient={'linear(to-b, red.100, pink.300)'}
                        borderBottomRadius={'20'}
                        zIndex={1}
                    />
                    <Text
                        bg={'rgba(255,255,255,0.5)'}
                        borderRadius={'10'}
                        padding={'1vh 1vw'}
                        position={'absolute'}
                        top={'5%'}
                        fontWeight={'bold'}
                        fontSize={'sm'}
                        left={'5%'}
                        color={'gray.900'}>
                        ново
                    </Text>
                    <VStack
                        paddingTop={'2vh'}
                        position={'absolute'}
                        top={'55%'}
                        left={0}
                        padding={'0 2vw'}
                        >
                        
                        <Heading
                            color={'black'}
                            fontSize={'2xl'}
                            fontWeight={'bold'}
                            verticalAlign={'top'}
                            zIndex={2}
                            >
                            {title}
                        </Heading>
                        <Text
                            color={'black'}
                            fontSize={'sm'}
                            fontWeight={'light'}
                            verticalAlign={'top'}
                            zIndex={2}
                            >
                            {subTitle}
                        </Text>
                        </VStack>
                    <Spacer 
                        height={'30vh'}
                    />

            </Box>
        
        </motion.div>
        </Link>
  )
}

export default News
