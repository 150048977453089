import React from 'react'
import { RootState } from '../store/store'
import { useDispatch } from 'react-redux'
import { toggleContactForm, setContactFormCustomText } from '../store/appSlice'
import { Button } from '@chakra-ui/react'

const ButtonContactForm = () => {
    const dispatch = useDispatch()
    const onContactToggle = () => {
        dispatch(setContactFormCustomText('За да научите повече за студиото, моля свържете се с нас:'))
        dispatch(toggleContactForm())
    }
  return (
    <Button
        bg={'pink.100'}
        marginTop={'5vh'}
        marginBottom={'3vh'}
        backdropFilter={'blur(10px)'}
        onClick={onContactToggle}
        >
        запази мястото си сега
    </Button>
  )
}

export default ButtonContactForm
