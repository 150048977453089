import React from 'react'
import {Box, Spinner, Center} from '@chakra-ui/react'

const Fallback = () => {
  return (
    <Box
        w="100%"
        h="100%"
        position="absolute"
        objectFit={'cover'}
        bg={'black'}
        top="0"
        left="0">
                <Spinner 
                    position={'absolute'}
                    top={'50%'}
                    left={'50%'}
                    size={'xl'}
                     color={'white'} />
    </Box>
  )
}

export default Fallback
