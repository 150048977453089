import { Text, Box, VStack, Center, Heading, Stack, FormControl,FormLabel,Input,Checkbox,Button } from '@chakra-ui/react'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import TextContent from '../components/TextContent'
import Footer from '../components/Footer'
import CourseList from '../components/CourseList'

const LoginPage = () => {
  return (
    <>

        <Box
            bgGradient={'linear(to-tr, pink.100, orange.200)'}
            width={'100%'}
            position="relative"
            padding={'12vh 0vw'}
             >
                <VStack
                    width={'fit-content'}
                    margin={'auto'}
                  >
                        <Stack align={'center'}
                            maxW={'90vw'}>
                            <Heading fontSize={'2xl'} textAlign={'center'}>Влез в своя акаунт</Heading>
                            <Text fontSize={'lg'} color={'gray.600'} textAlign={'center'}>
                                за да се насладиш на всички статии и видео уроци.
                            </Text>
                            <Text fontSize={'sm'} color={'gray.600'}>
                                Налично само за ученици с месечен и годишен абонамент.
                            </Text>
                        </Stack>
                        <Center
                          bg={'rgba(255,255,255,0.5)'}
                          borderRadius={'10'}
                          w={'100%'}
                          minH={{base:'20vh', md:'50vh'}}
                          padding={'6vh 2vw'}>
                            <Center>
                                <Stack spacing={4}>
                                    <FormControl id="email">
                                    <FormLabel>Имейл</FormLabel>
                                    <Input type="email" />
                                    </FormControl>
                                    <FormControl id="password">
                                    <FormLabel>Парола</FormLabel>
                                    <Input type="password" />
                                    </FormControl>
                                    <Stack spacing={10}>
                                    <Stack
                                        direction={{ base: 'column', sm: 'row' }}
                                        align={'start'}
                                        justify={'space-between'}>
                                        <Checkbox>Запомни ме</Checkbox>
                                        <Text color={'gray.600'}>Забравена парола?</Text>
                                    </Stack>
                                    <Button
                                        bg={'gray.400'}
                                        color={'white'}
                                        disabled={true}
                                        _hover={{
                                        bg: 'gray.500',
                                        }}>
                                        Налично от 20ти септември
                                    </Button>
                                    </Stack>
                                </Stack>
                            </Center>
                        </Center>

                </VStack>

        </Box>
        <Footer></Footer>

    </>
  )
}

export default LoginPage
