import { Text, Box, VStack, Center } from '@chakra-ui/react'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import TextContent from '../components/TextContent'
import Footer from '../components/Footer'
import CourseList from '../components/CourseList'
import NewsList from '../components/NewsList'

import { newsData } from '../data/data'

const NewsPage = () => {
  return (
    <>

        <Box
            bgGradient={'linear(to-tr, pink.100, orange.200)'}
            width={'100%'}
            position="relative"
            padding={'12vh 0vw'}
             >
                <VStack
                    width={{base:'90vw',md:'80vw'}}
                    margin={'auto'}
                  >
                    <Text
                        fontSize={'2xl'}
                        fontFamily={'heading'}
                        fontWeight={'bold'}
                        padding={'2vh 0'}
                        >Актуално</Text>
                        <Center
                          bg={'rgba(255,255,255,0.5)'}
                          borderRadius={'10'}
                          w={'100%'}
                          minH={{base:'20vh', md:'50vh'}}
                          padding={'6vh 2vw'}>
                            <Center>
                            <NewsList news={newsData}/>
                            </Center>
                        </Center>

                </VStack>

        </Box>
        <Footer></Footer>

    </>
  )
}

export default NewsPage
