import React from 'react'
import { Text, SimpleGrid, VStack } from '@chakra-ui/react'
import Carousel from '../components/Carousel'
import TextContent from '../components/TextContent'

import Footer from '../components/Footer'
import ButtonContactForm from '../components/ButtonContactForm'

import { useParams } from 'react-router-dom'
import { newsData } from '../data/data'

const SingleNewsPage = () => {
    const {id} = useParams<{id: string}>()

    const news = newsData.find((news) => news.id === id)
    if (!news) {
        return (
            <Text textAlign={'center'}>No news available</Text>
        )
    }

    return (
      <>
          {<Carousel images={
              [news.image]
          }/>}
          <Text
              marginLeft={'auto'}
              marginRight={'auto'}
              width={{base:'80vw',md:'60vw'}}
              fontSize={'4xl'}
              marginTop={{base:'2vh', md:'5vh'}}
              fontFamily={'heading'}
              fontWeight={'bold'}
              verticalAlign={'top'}
              align={'left'}
              >
              {news?.title}
          </Text>
          <SimpleGrid
            marginLeft={'auto'}
            marginRight={'auto'}
            width={{base:'80vw',md:'60vw'}}
            marginTop={{base:'2vh', md:'5vh'}}
            marginBottom={{base:'2vh', md:'5vh'}}
            columns={{base:1, lg:1}}
            columnGap={10}>
            <TextContent
                title={''}
                subTitle={''}
                content={news.content}
            />
            {/*<VStack
              borderLeft={'4px solid'}
              borderColor={'white'}
              paddingTop={'0'}
              marginTop={{base:'5vh',md:'0'}}
              >
                <Text
                  fontSize={'2xl'}
                  fontWeight={'light'}
                  >
                  Налични класове:
                </Text>
                <SimpleGrid
                  columns={{base:2, lg:2}}
                  alignContent={'left'}
                  gap={'1vw'}
                  >
                </SimpleGrid>
  
                <ButtonContactForm />
  
        </VStack>*/}
  
            
          </SimpleGrid>
  
          <Footer />
  
      </>
    )
  }

export default SingleNewsPage
