import React from 'react'
import { Box, Image, Center, Text, Button, Heading, Flex, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import ContactForm from './ContactForm'

import DancerImage from './DancerImage'
import star from '../assets/star.png'

import { useDispatch } from 'react-redux'
import { RootState } from '../store/store'
import { toggleContactForm, setContactFormCustomText } from '../store/appSlice'

interface StarProps {
    delay: number;
    x: number;
    y: number;
    duration: number;
}

const Star = ( {x, y, duration} : StarProps ) => {

    const [rand, setRand] = useState(0)
    useEffect(()=> {
        setRand(Math.floor(Math.random()*10))
    }, [])

    return(
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: duration || 1, delay: 0, repeat: Infinity, repeatType: 'reverse' }}
                onAnimationIteration={  () => {console.log("AM")}}
                
                >
                <Image
                    dropShadow={'16px 16px 10px black'}
                    src={star}
                    alt='star'
                    zIndex={1}
                    position={'absolute'}
                    top={`${y+rand}vh`}
                    left={`${x+rand}vw`}
                    h={'5vh'}
                    w={'5vh'}
                    />
            </motion.div>
        </>
    )
}


const StarField = () =>{

    return(
        <>
            <Star delay={3} x={15} y={15} duration={5}/>
            <Star delay={1} x={30} y={40} duration={3}/>
            <Star delay={2} x={12} y={70} duration={2}/>
            <Star delay={1} x={80} y={10} duration={3}/>
            <Star delay={1} x={70} y={40} duration={3}/>
            <Star delay={1} x={10} y={40} duration={1}/>
            <Star delay={3} x={82} y={66} duration={5}/>
        </>
    )
  
}

const HeroImage = () => {

    const dispatch = useDispatch()

    const bookFreeLesson = () => {
        dispatch(setContactFormCustomText('Ако искате да резервирате безплатен първи урок, моля свържете се с нас тук:'))
        dispatch(toggleContactForm())
    }

    const reserveSpot = () => {
        dispatch(setContactFormCustomText('За да подсигурите място си за предстоящите ни класове, моля свържете се с нас тук:'))
        dispatch(toggleContactForm())
    }

  return (
    <Box
        height={{base:'81vh', md:'85vh'}}
        width={'100%'}
        bgGradient={'linear(to-tr, pink.300, red.400)'}>
            <StarField />
            
            <Center
                flexDirection={'column'}
                maxH={'90vh'}
                gap={'3vh'}
                zIndex={2}
                >
                <Center
                    flexDirection={'column'}>
                    <Heading
                        fontSize={{base:'2xl',md:'4xl'}}
                        color={'white'}
                        paddingTop={'13vh'}
                        zIndex={3}
                        textAlign={'center'}
                        >
                        Сезон 2024 / 2025
                    </Heading>
                    <Text
                        fontSize={{base:'xl',md:'xl'}}
                        fontFamily={'body'}
                        fontWeight={'light'}
                        color={'white'}
                        zIndex={3}
                        textAlign={'center'}
                        >
                        Започваме на 2 септември
                    </Text>
                </Center>
                <DancerImage />
                <SimpleGrid
                    marginTop={{base:'0vh',md:'1vh', "2xl":'5vh'}}
                    columns={{base:1, sm:1}}
                    gap={'3vw'}>
                    <Button
                        bg={'rgba(255,255,255,0.3)'}
                        backdropFilter={'blur(10px)'}
                        onClick={reserveSpot}
                        >
                        запази мястото си сега
                    </Button>
                </SimpleGrid>
            </Center>
      
    </Box>
  )
}

export default HeroImage
