import { INews } from "./types"



export const newsData : INews[] =[
    {
        title : "Балетен курс „Мама и аз\"",
        subTitle : "",
        image : "https://res.cloudinary.com/daduurukb/image/upload/v1698409258/inchilza/mama.png" ,
        content: ["Искате да запишете детето си на балет, но всички танцови школи Ви казват, че е прекалено малко, е в такъв случай това е вашето място." , "Ние Ви предлагаме 40 минутно танцово приключение с вашето дете, където заедно ще се забвляваме, учим и танцуваме. Подходящо за бебоци от 1 до 3 годишна възраст." , "Очакваме Ви с нетърпение!"],
        id: "1"
    }
]
