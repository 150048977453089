import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Image, Box, Fade } from '@chakra-ui/react'
import { useState, useEffect } from 'react'

import dancer1 from '../assets/dancer.png'
import dancer2 from '../assets/dancer2.png'
import dancer3 from '../assets/dancer3.png'



const images = [
    dancer1,
    dancer2,
    dancer3,
]

const DancerImage = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((currentImageIndex + 1) % images.length)
        }, 3000)
        return () => clearInterval(intervalId)
      }, [currentImageIndex, images.length])

  return (
    <Box 
        h={{base:'35vh', sm:'45vh'}}
        width={'100%'}
        position={'relative'}
        >
        {images.map((imageUrl, index) => (
        <Fade key={imageUrl} in={index === currentImageIndex}>
          <Image
                src={imageUrl}
                alt={`Dancer Image ${index}`}
                h={{base:'35vh', md:'45vh'}}
                position={'absolute'}
                top={0}
                left={'0'}
                right={'0'}
                margin={'auto'}

                zIndex={3}
            />
        </Fade>
      ))}
    </Box>
  )
}

export default DancerImage
