import React from 'react'
import { Text, Box, SlideFade, Fade, Image, Button, AspectRatio, VStack, Divider, Link} from '@chakra-ui/react'
import Logo from '../assets/logo_black.png'
import { EmailIcon, PhoneIcon, CloseIcon  } from '@chakra-ui/icons'

type props = {
    isOpen: boolean;
    toggleOpen: () => void;
    customText?: string;
}

const ContactForm = ({isOpen, toggleOpen, customText}:props) => {
    
    
    return (
        <>
            {isOpen &&
                <Box
                    position={'fixed'}
                    top={'0'}
                    left={'0'}
                    width={'100vw'}
                    height={'100vh'}
                    bg={'rgba(0,0,0,0.5)'}
                    backdropFilter={'blur(10px)'}
                    zIndex={10}
                    onClick={toggleOpen}
                    pointerEvents={isOpen ? 'all' : 'none'}
                >
                    
                <SlideFade in={isOpen} offsetY="20px">
                    
                    <Box
                        position={'relative'}
                        top={{base:'11vh',md:'10vh'}}
                        left={'0'}
                        right={'0'}
                        margin={'auto'}
                        borderRadius={'10'}
                        onMouseDown={() => {console.log("MAMT")}}
                        width={{base: '90vw',md:'40vw'}}
                        pointerEvents={'all'}
                        padding={'5vh 3vw'}
                        bg={'rgba(255,255,255,0.5)'}
                        zIndex={99999}
                        >
                            
                            {/*<Image
                                src={Logo}
                                alt={'Inchilza Dance School Logo'}

                                w={'40vh'}
                            />*/}
                            <VStack
                                gap={7}>
                            <Text
                                textAlign={'center'}
                                fontSize={{base:'xl',md:'2xl'}}>
                                {customText || 'За да научите повече за студиото, моля свържете се с нас:'}
                            </Text>
                            <VStack
                                gap={3}>
                            <Text
                                fontSize={'lg'}
                                fontWeight={'light'}
                                _hover={
                                    {
                                        fontWeight:'bold'
                                    }
                                }>
                                <Link href='mailto:info@inchilzadancestudio.com'>
                                    <Button
                                        bg={'rgba(255,255,255,0.2)'}
                                        _hover={{
                                            bg:'rgba(255,255,255,0.4)'
                                        }}
                                        >
                                        <EmailIcon/>&nbsp;info@inchilzadancestudio.com
                                    </Button>
                                </Link>
                            </Text>
                            <Link href='tel:+359885602809'>
                                <Button
                                    bg={'rgba(255,255,255,0.2)'}
                                    _hover={{
                                        bg:'rgba(255,255,255,0.4)'
                                    }}
                                    >
                                    <PhoneIcon/>&nbsp;+359 88 560 2809
                                </Button>
                            </Link>
                            
                            

                            </VStack>
                            <Divider orientation='horizontal'/>

                        
                            <AspectRatio 
                                ratio={16 / 6}>
                            <iframe
                                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.952912260219!2d3.375295414770757!3d6.5276316452784755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2ae68280c1%3A0xdc9e87a367c3d9cb!2sLagos!5e0!3m2!1sen!2sng!4v1567723392506!5m2!1sen!2sng'
                            />
                            </AspectRatio>
                            </VStack>
                            
                    
                    </Box>
                </SlideFade>
            </Box>
            }
        </>
    )
}

export default ContactForm
