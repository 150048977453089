import React from 'react'
import Navbar from '../components/Navbar'
import HeroImage from '../components/HeroImage'
import CourseList from '../components/CourseList'
import NewsList from '../components/NewsList'
import Footer from '../components/Footer'
import { Box, SlideFade, VStack } from '@chakra-ui/react'
import Quote from '../components/Quote'

import ballet8 from '../assets/course8.jpg'
import moderny from '../assets/moderny.jpg'
import stretching from '../assets/stretching.jpg'

const courses = [
  {
    title: 'Класически балет за деца',
    subTitle: '3-4 години, 5-7 години, 8+ години',
    image: ballet8,
    href: '/ballet'
  },
  {
    title: 'Модерен балет за деца',
    subTitle: 'начинаещи, напреднали',
    image: moderny,
    href: '/modern'
  },
  {
    title: 'Стречинг и мобилност на тялото',
    subTitle: 'възрастни',
    image: stretching,
    href: '/stretching'
  },
]

const HomePage = () => {

  return (
    <SlideFade in={true} offsetY='-20px'>
      <HeroImage />
      <VStack
        bg={'rgba(255,255,255,0.5)'}
        borderRadius={'10'}
        margin={'auto'}
        w={{base:'90vw',sm:'80vw'}}
        padding={'6vh 0'}
        gap={'6vh'}>
      <CourseList courses={courses}/>
      <Quote>
        "Inchilza is an award winning dance school in Sofia. We offer an international approach to ballet and contemporary dance."
      </Quote>
      </VStack>
      <Footer />
    </SlideFade>
  )
}

export default HomePage
