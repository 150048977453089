import { Text, HStack, SimpleGrid, VStack, Divider, Button, Box } from '@chakra-ui/react'
import Navbar from '../components/Navbar'
import Carousel from '../components/Carousel'
import TextContent from '../components/TextContent'
import Footer from '../components/Footer'
import ScheduleTable from '../components/ScheduleTable'

const SchedulePage = () => {
  return (
    <>
        <Box
            bgGradient={'linear(to-tr, pink.100, orange.200)'}
            width={'100%'}
            position="relative"
            padding={'12vh 10vw'}
             >
                <VStack>
                    <Text
                        fontSize={'2xl'}
                        fontFamily={'heading'}
                        fontWeight={'bold'}
                        padding={'2vh 0'}
                        >График</Text>

                    <ScheduleTable />
                        
                </VStack>

        </Box>
        <Footer></Footer>
      
    </>
  )
}

export default SchedulePage
